.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100vh;
  min-height: 100vh;
  overflow: auto;
}

/* login css */
.login {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100vh;
}

.login__form {
  width: 30%;
  justify-self: center;
  align-self: center;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  padding: 45px 22px;
  border-radius: 10px;
  background-color: #ffffff;
}
.login__form h4{
  text-align: left;
  font-size: 16px;
  color: var(--color-darkCerulean);
  margin-bottom: 30px;
  letter-spacing: 0.5px;
  font-weight: 600;
  line-height: 22px;
}
.login-form .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  display: none;
}
.login-form label{
  font-size: 12px;
  color: #3B3B3B;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding-left: 10px;
}
.createPwd-form-button {
  background-color: var(--color-deepsky);
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.3px;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  float: left;
  margin-top: 20px;
  height: 50px;
  text-align: center;
}
/* responsive code */
/* @media screen and (max-width: 1340px) {
  .container {
    width: 90%;
  }
} */
@media screen and (max-width: 1280px) {
  .login__form {
    width: 40%;
  }
}

/* @media screen and (max-width: 1185px) {
  .container {
    width: 98%;
  }
} */
