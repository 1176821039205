body {
  font-family: "Quicksand", sans-serif;
  --color-deepskyblue: #00a7ee;
  --color-darkCerulean: #005081;
  --color-darkCeru: #005085;
  --color-applecolor: #00a9fa;
  --color-deepsky: #00a6e8;
  --color-gray: #696969;
  --color-white: #ffffff;
  --color-black: #333333;
  --color-lightGray: rgba(193,193,193,0.87);
  --color-darkBlue: #00456F;
  --color-primary: #00A6E8;
  --color-deepRed: #850000;
  --color-lightGrey: #E7E7E7;
  --color-lightBlue: #85BCD1;
  --container-width: 90%;

}
